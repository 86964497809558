export default {
  REFERRAL: {
    FORM_TITLE: 'Welcome to Velocity Black',
    FORM_INFO: 'Enter your application code to continue',
    FORM_ASTERISK: null,
    BUTTON_TEXT: 'Apply to join',
    INFO: {
      HERO: {
        TITLE: null,
        CONTENT: null,
      },
      SECTION: {
        TITLE: 'You’re pre-approved',
        CONTENT: null,
      },
    },
    THANKS: {
      TITLE: null,
      CONTENT: null,
    },
  },
  REGISTRATION: {
    NEXT: {
      TITLE: null,
      CONTENT: null,
    },
    NEW_MEMBER: {
      FORM_ASTERISK_START: null,
      FORM_ASTERISK_MIDDLE: null,
      FORM_ASTERISK_PRIVACY: null,
      FORM_ASTERISK_END: null,
    },
  },
  FORM_LABELS: {
    RESIDENCE: null,
  },
  PRIVACY_LINK: null,
  EXCLUDED_FORM_FIELDS: null,
}
