import clsx from 'clsx'
import registrationStyles from '../Views/Registration/Registration.module.scss'
import velocityStyles from '../Views/Registration/Registration.Velocity.module.scss'
import allureStyles from '../Views/Registration/Registration.Allure.module.scss'
import jaguarStyles from '../Views/Registration/Registration.Jaguar.module.scss'
import penkeyStyles from '../Views/Registration/Registration.Peninsula.module.scss'
import jaguarContent from 'content/jaguar.content'
import penkeyContent from 'content/penkey.content'
import velocityContent from 'content/velocity.content'
import allureContent from 'content/allure.content'

type Content = typeof velocityContent | typeof allureContent | typeof jaguarContent | typeof penkeyContent

export enum Business {
  Velocity = 'Velocity',
  Alandsbanken = 'Alandsbanken',
  Allure = 'Allure',
  BLME = 'BLME',
  ChargersLux = 'ChargersLux',
  ClermontClub = 'ClermontClub',
  Jaguar = 'Jaguar',
  Moderna = 'Moderna',
  Onyx = 'Onyx',
  RRMC = 'RRMC',
  Swiss4 = 'Swiss4',
  Peninsula = 'Peninsula',
}

export const isVelocity = process.env.REACT_APP_BUSINESS === Business.Velocity

export const skipToThankYou = [Business.Jaguar, Business.Peninsula].includes(process.env.REACT_APP_BUSINESS as Business)

export const hideFloatingLabel = process.env.REACT_APP_BUSINESS === Business.Allure

export const enablePricingFlow = process.env.REACT_APP_BUSINESS === Business.Velocity

export const hasLightBackground = [Business.Jaguar, Business.Peninsula].includes(
  process.env.REACT_APP_BUSINESS as Business,
)

export const hasRegistrationVideo = [Business.Peninsula].includes(process.env.REACT_APP_BUSINESS as Business)

export const showSectionContent = [Business.Jaguar].includes(process.env.REACT_APP_BUSINESS as Business)

export const showPoweredBy = ![Business.Peninsula].includes(process.env.REACT_APP_BUSINESS as Business)

export const hasRegistrationPage = [Business.Allure, Business.Jaguar, Business.Velocity, Business.Peninsula].includes(
  process.env.REACT_APP_BUSINESS as Business,
)

export const shouldUseBusinessTheme = process.env.REACT_APP_USE_BUSINESS_THEME === 'true'

export const shouldEnableWebApp = process.env.REACT_APP_ENABLE_WEBAPP === 'true'

export const getBusinessName = (): string => {
  switch (process.env.REACT_APP_BUSINESS) {
    case Business.Velocity:
      return 'Velocity Black'
    default:
      return process.env.REACT_APP_BUSINESS || ''
  }
}

export const getRegistrationStyles = (...classes: readonly string[]): string => {
  const defaultStyles: readonly string[] = classes.map((className) => registrationStyles[className])

  switch (process.env.REACT_APP_BUSINESS) {
    case Business.Velocity:
      return clsx(
        defaultStyles,
        classes.map((className) => velocityStyles[className]),
      )
    case Business.Jaguar:
      return clsx(
        defaultStyles,
        classes.map((className) => jaguarStyles[className]),
      )
    case Business.Peninsula:
      return clsx(
        defaultStyles,
        classes.map((className) => penkeyStyles[className]),
      )
    case Business.Allure:
      return clsx(
        defaultStyles,
        classes.map((className) => allureStyles[className]),
      )
    default:
      return clsx(defaultStyles)
  }
}

export const getBusinessContent = (): Content => {
  switch (process.env.REACT_APP_BUSINESS) {
    case Business.Jaguar:
      return jaguarContent
    case Business.Peninsula:
      return penkeyContent
    case Business.Allure:
      return allureContent
    default:
      return velocityContent
  }
}
