import React, { FC } from "react";
import { ActivityIndicator } from "react-native";
import { THEMES } from "../../../../apps/AUTO_GENERATED_env";
import { colors } from "../../utils/hooks/useColorTheme";

const WebLoadingScreen: FC = () => (
  <ActivityIndicator
    color={THEMES.rawColors.WHITE}
    size='large'
    style={{ backgroundColor: colors?.loadingScreenBackground || THEMES.rawColors.GREY_1, height: '100%' }}
  />
)

export default WebLoadingScreen
