import { THEMES } from '../../../../apps/AUTO_GENERATED_env'
import { ThemeConfig } from '../../../theming/types/ThemesConfig'

// eslint-disable-next-line @typescript-eslint/ban-types
const useColorTheme = (): Partial<ThemeConfig> => {
  return THEMES.light
}

export const theme = THEMES.light
export const { colors, textColors } = THEMES.light


export default useColorTheme
