import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Router from './Views/Router/Router'
import { FONT_FILES_PAIRING as BUSINESS_FONT_FILES_PAIRING, THEMES } from '@vb/app/apps/AUTO_GENERATED_env'

const FAVORIT_FONT_FILES_PAIRING = {
  'FavoritPro-Light': 'Favorit/FavoritPro-Light.otf',
  'FavoritPro-Book': 'Favorit/FavoritPro-Book.otf',
  'FavoritPro-Regular': 'Favorit/FavoritPro-Regular.otf',
  'FavoritPro-Medium': 'Favorit/FavoritPro-Medium.otf',
  'FavoritPro-Bold': 'Favorit/FavoritPro-Bold.otf',
}

const FONT_FILES_PAIRING = { ...FAVORIT_FONT_FILES_PAIRING, ...BUSINESS_FONT_FILES_PAIRING }

const customFontStyles = Object.keys(FONT_FILES_PAIRING).reduce((fonts, fontName) => {
  const fontFile = FONT_FILES_PAIRING[fontName]
  return `${fonts}
  @font-face {
    src: url('/assets/fonts/${fontFile}');
    font-family: ${fontName};
  }`
}, '')

const customCalendarStyles = `
.Cal__Weekdays__day {
  color: ${THEMES.light.colors.diningRequestPrimary || '#aaa'};
}

.Cal__Container__wrapper .Cal__Month__rows {
  background: ${THEMES.light.colors.diningRequestPrimary || '#fff'};
}

.Cal__Month__rows .Cal__Month__row:first-child li {
  background-color: ${THEMES.light.colors.diningRequestPrimary || '#fff'};
  box-shadow: 0 -1px 0 ${THEMES.light.colors.diningRequestPrimary || '#e9e9e9'};
}

.Cal__Month__row .Cal__Day__root.Cal__Day__disabled {
  color: ${THEMES.light.colors.diningRequestDisabled || '#ddd'};
}

.Cal__Month__row .Cal__Day__root.Cal__Day__today > span {
  color: ${THEMES.light.colors.diningRequestSecondary || '#3d3d3d'};
}

.Cal__Day__root {
  color: ${THEMES.light.colors.diningRequestSecondary || '#3d3d3d'};
}

.Cal__Month__rows .Cal__Month__row.Cal__Month__partial:first-child li:first-child {
  box-shadow: 0px -1px 0 ${THEMES.light.colors.diningRequestPrimary || '#e9e9e9'}, inset 1px 0 0 ${
  THEMES.light.colors.diningRequestPrimary || '#e9e9e9'
};
}

.Cal__Month__rows .Cal__Month__row:nth-child(2) {
  box-shadow: 0 -1px 0 ${THEMES.light.colors.diningRequestPrimary || '#e9e9e9'};
}
`

const customStyles = `${customFontStyles}${customCalendarStyles}`

if (process.env.REACT_APP_ROOT_ID) {
  ReactDOM.render(
    <React.StrictMode>
      <Router />
    </React.StrictMode>,
    document.getElementById(process.env.REACT_APP_ROOT_ID),
    () => {
      const style = document.createElement('style')
      style.type = 'text/css'
      if (style.styleSheet) {
        style.styleSheet.cssText = customStyles
      } else {
        style.appendChild(document.createTextNode(customStyles))
      }
      document.head.appendChild(style)
    },
  )
} else {
  console.error(
    'Cannot mount Web App because process.env.REACT_APP_ROOT_ID is undefined',
    process.env.REACT_APP_ROOT_ID,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
