export default {
  REFERRAL: {
    FORM_TITLE: 'Enter your pin',
    FORM_INFO: '',
    FORM_ASTERISK: '',
    BUTTON_TEXT: 'Submit',
    INFO: {
      HERO: {
        TITLE: 'Welcome to the next generation of PenKey',
        CONTENT: 'The ultimate membership for an extraordinary life',
      },
      SECTION: {
        TITLE: null,
        CONTENT: `Powered by the world’s leading digital lifestyle concierge, Velocity, this service is provided exclusively to PenKey members to help create a Peninsula experience like no other.  Membership in the Mobile PenKey Concierge will provide you with instant access to a world of exclusive events and opportunities, expertly curated and sourced for you, available via this bespoke app and messaging service.
        In order to ensure you are among the first to enjoy this next generation of unparalleled access, please complete the following details to create your private account.`,
      },
    },
    THANKS: {
      TITLE: 'Thank You',
      CONTENT:
        'You are one step closer to a life without limits. Our membership team will be in touch with you via email and SMS in the next few days.',
    },
  },
  REGISTRATION: {
    NEXT: {
      TITLE: '',
      CONTENT: ``,
    },
    NEW_MEMBER: {
      FORM_ASTERISK_START: 'I have read through and agree with the ',
      FORM_ASTERISK_MIDDLE: ', the',
      FORM_ASTERISK_PRIVACY: 'Data Privacy and Security Policy',
      FORM_ASTERISK_END:
        ' and HSH Group’s privacy practices, including transferring my personal information to HSH’s affiliates and third-party service providers overseas.',
    },
  },
  FORM_LABELS: {
    RESIDENCE: 'Primary residential address',
  },
  PRIVACY_LINK: 'https://www.peninsula.com/en/global-pages/data-privacy-and-security',
  EXCLUDED_FORM_FIELDS: ['occupation', 'location'],
}