import { createTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import styles from './theme.module.scss'

const defaultPalette: PaletteOptions = {
  primary: {
    main: styles.gold,
  },
  secondary: {
    main: styles.grey,
  },
}

export const darkTheme = createTheme({
  palette: {
    ...defaultPalette,
    type: 'dark',
    background: {
      default: styles.black,
    },
  },
})

export const lightTheme = createTheme({
  palette: {
    ...defaultPalette,
    type: 'light',
    background: {
      default: styles.white,
    },
  },
})
