export default {
  REFERRAL: {
    FORM_TITLE: 'Skip the Queue',
    FORM_INFO: `Powered by the world’s leading digital lifestyle concierge, Velocity*, this exclusive service already has a waiting list – and a substantial price tag – but we’d like to invite you to try it, for a year, with our compliments.

Skip the queue to join Jaguar Concierge today and you’ll enjoy instant access to a world of exclusive experiences, expertly curated and sourced for you by our team, all via our exclusive app and messaging service.

As you’d imagine, this is a highly sought-after service, so to complete your registration, please complete the fields below no later than 24/06. The Jaguar Concierge team will be in contact within 24-hours to confirm your registration and begin your onboarding experience.

We look forward to welcoming you to Jaguar Concierge.  `,
    FORM_ASTERISK: '*Velocity is the trading name of Velocity Mobile Limited ',
    BUTTON_TEXT: 'Apply to join',
    INFO: {
      HERO: {
        TITLE: 'Introducing jaguar concierge',
        CONTENT:
          'Thank you for your interest in being the first to join Jaguar Concierge, the ultimate membership for an extraordinary life.',
      },
      SECTION: {
        TITLE: '24/7/365 Human Digital Concierge',
        CONTENT: `Using cutting-edge technology alongside global luxury expertise, Jaguar Concierge transforms how you discover the world.

Thanks to the custom-built mobile app, you can make concierge requests 24 hours a day, 365 days a year. From access to fully booked restaurants and rare luxury goods, to bucket list experiences and once-in-a-lifetime holidays, a dedicated team of experts is on hand with a guaranteed response in just 60 seconds.`,
      },
    },
    THANKS: {
      TITLE: null,
      CONTENT: null,
    },
  },
  REGISTRATION: {
    NEXT: {
      TITLE: 'What happens next?',
      CONTENT:
        'Velocity will be in touch to take care of the onboarding process and may share your information with Jaguar Land Rover Limited for verification purposes.  Jaguar Concierge is provided by Velocity, with whom your services contract will be formed and who is a controller of any personal information received about you.',
    },
    NEW_MEMBER: {
      FORM_ASTERISK_START: null,
      FORM_ASTERISK_MIDDLE: null,
      FORM_ASTERISK_PRIVACY: null,
      FORM_ASTERISK_END: null,
    },
  },
  FORM_LABELS: {
    RESIDENCE: null,
  },
  PRIVACY_LINK: null,
  EXCLUDED_FORM_FIELDS: null,
}
