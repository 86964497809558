import React, { Suspense, lazy } from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { darkTheme, lightTheme } from '../../theme/Theme'
import { businessTheme } from '../../theme/ThemeForBusiness'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useQueryParams } from '../../utils/hooks'
import { isVelocity, hasRegistrationPage, shouldUseBusinessTheme, shouldEnableWebApp } from '../../utils/business'
import WebLoadingScreen from '@vb/app/src/modules/navigation/components/WebLoadingScreen'

const PaymentProvider = lazy(() => import('../PaymentProvider/PaymentProvider'))
const AuthorisedDelegates = lazy(() => import('../AuthorisedDelegates/AuthorisedDelegates'))
const DeeplinkFallback = lazy(() => import('../DeeplinkFallback/DeeplinkFallback'))
const Registration = lazy(() => import('../Registration/Registration'))
const Landing = lazy(() => import('../Landing/Landing'))
const Splash = lazy(() => import('../Splash/Splash'))
const App = lazy(() => import('@vb/app/src/WebApp'))

const AppRouter = () => (
  <Router>
    <Suspense
      fallback={
        <div style={{ width: '100%' }}>
          <WebLoadingScreen />
        </div>
      }
    >
      <Switch>
        <Route
          path={[
            '/app-link',
            '/authenticate',
            '/member/:memberId/payment/methods/add/:preferredProvider',
            '/member/:memberId/payment/request/:paymentRequestId/:preferredProvider',
            '/member/:memberId/payment/request/:paymentRequestId',
            '/member/:memberId/payment/methods/add',
            '/member/:memberId/payment/:paymentId/verify',
            '/member/:memberId/delegates/:tokenId',
            '/register',
            ...(shouldEnableWebApp ? [] : ['/']),
          ]}
          component={BusinessRouter}
        />
        {shouldEnableWebApp && <Route path="/" component={App} />}
      </Switch>
    </Suspense>
  </Router>
)

const BusinessRouter = () => {
  const { theme } = useQueryParams()

  return (
    <Router>
      <ThemeProvider theme={shouldUseBusinessTheme ? businessTheme : theme === 'light' ? lightTheme : darkTheme}>
        <CssBaseline />
        <Switch>
          <Route path="/app-link" component={DeeplinkFallback} />
          {isVelocity && <Route path="/authenticate" component={Landing} />}
          <Route
            path={[
              '/member/:memberId/payment/methods/add/:preferredProvider',
              '/member/:memberId/payment/request/:paymentRequestId/:preferredProvider',
              '/member/:memberId/payment/request/:paymentRequestId',
              '/member/:memberId/payment/methods/add',
              '/member/:memberId/payment/:paymentId/verify',
            ]}
            component={PaymentProvider}
          />
          <Route path="/member/:memberId/delegates/:tokenId" component={AuthorisedDelegates} />
          {hasRegistrationPage && <Route path="/register" component={Registration} />}
          <Route path="/" component={isVelocity ? Landing : Splash} />
        </Switch>
      </ThemeProvider>
    </Router>
  )
}

export default AppRouter
