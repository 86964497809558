import { useLocation } from 'react-router-dom'

export const useQuery = (): URLSearchParams => new URLSearchParams(useLocation().search)

export const useQueryParams = <T extends Record<string, string> = Record<string, string>>(): T =>
  Array.from(useQuery().entries()).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {} as T,
  )
