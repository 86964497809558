import { createTheme } from '@material-ui/core/styles'
import styles from './theme.module.scss'

export const businessTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: styles.black,
    },
  },
})
